@import '~antd/dist/antd.css';

.ant-carousel .slick-dots-bottom li button{
   background:blue
}

.ant-carousel .slick-dots-bottom li.slick-active  button {
   background:blue
}

.changed-input input,
.changed-input .ant-select,
.changed-input .ant-radio-button-checked{
    color:green;
}
.changed-input input,
.changed-input .ant-select{
    border:3px greenYellow solid;
}
.changed-input .ant-radio-button-wrapper-checked{
    background-color:greenYellow;
}
.ant-checkbox-group-item {
    display: flex;
    width:100%;
    margin-right: 0;
}